import Persian from './Persian'

export function toEnglish(val) {
  return val ? Persian(val).toEnglishNumber().toString() : val
}

export function toPersian(val) {
  if (val === 0) return '۰'
  return val ? Persian(val).englishNumber().toString() : val
}

export function formatPrice(val) {
  return String(val).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function persianPrice(val) {
  return toPersian(formatPrice(val))
}
