/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'

import FACILITIES_ACTION_TYPES from '../constants/FacilitiesConstants'
import initialState from '../initialState'

function all(state = initialState.facilities.all, action) {
  switch (action.type) {
    case FACILITIES_ACTION_TYPES.SET_ALL_FACILITIES:
      return [...action.list]

    default:
      return state
  }
}

function requested(state = initialState.facilities.requested, action) {
  switch (action.type) {
    case FACILITIES_ACTION_TYPES.SET_ALL_FACILITIES_REQUEST:
      return Array.isArray(action.list) ? [...action.list] : []

    case FACILITIES_ACTION_TYPES.CLEAR_REQUESTS:
      return []

    default:
      return state
  }
}

function terms(state = initialState.facilities.terms, action) {
  switch (action.type) {
    case FACILITIES_ACTION_TYPES.SET_TERMS:
      return action.terms

    default:
      return state
  }
}

export default combineReducers({
  all,
  requested,
  terms,
})
