import React from 'react'
import BuildingCostsCard from '../buildingCostsCard/BuildingCostsCard'

const BuildingCostsList = ({ data }) => {
  // const requests = [
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: false,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: false,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: false,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  //   {
  //     title: '۱.نگه داری ماهانه آسانسور',
  //     month: 'تیر ماه',
  //     price: ['۱۲،۰۰۰', 'تومان'],
  //     payed: true,
  //   },
  // ]

  return (
    <div>
      {data?.map((e) => {
        return <BuildingCostsCard id={e.id} month={e.month} payed={e.payed} price={e.price} title={e.title} />
      })}
    </div>
  )
}

export default BuildingCostsList
