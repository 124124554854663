import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import './styles.scss'
import ManagerFacilities from '../../../components/managerFacilities'
import ResidentFacilities from '../../../components/residentFacilities'

const Facilities = () => {
  const routeMatch = useRouteMatch()

  return routeMatch.params.role === 'manager' ? <ManagerFacilities /> : <ResidentFacilities />
}

export default Facilities
