/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFilter } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment-jalaali'

import DatePicker from 'react-modern-calendar-datepicker'
import Service from '../../Api/Service'

import Button from '../../../components/button/Button'
import Modal from '../../../components/Modal/ModalPage'
import ManagerHeader from '../../../components/managerHeader'
import ResidentHeader from '../../../components/residentHeader'
import BuildingCostsList from '../../../components/buildingCostsList/BuildingCostsList'

// import SelectBoxPro from '../../../components/SelectBoxPro/SelectBoxPro'

import * as SharedActions from '../../redux/actions/SharedActions'

import SelectBox from '../../../components/selectBox'
import Input from '../../../components/input/Input'

import './buildingCosts.scss'

import { useModal } from '../../hooks/useModal'

const BuildingCosts = () => {
  const BUILDING_COSTS_MODAL = 'BUILDING_COSTS_MODAL'
  const toggleModal = useModal(BUILDING_COSTS_MODAL)

  const [data, setData] = useState([])
  const [date, setDate] = useState(null)

  const [price, setPrice] = useState(null)
  const [budgets, setBudgets] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [description, setDescription] = useState(null)

  const residenceId = useSelector((state) => state.shared.residence_id)
  const userType = useSelector((state) => state.shared.userType) || 'manager'

  useEffect(() => {
    callManagerRequest()
    callBudgets()
  }, [])

  // Redux actions
  const dispatch = useDispatch()

  const callManagerRequest = async () => {
    const response = await Service.GET_ALL_COSTS({ id: residenceId })
    setData(response?.data)
  }

  const callBudgets = async () => {
    const response = await Service.GET_COST_BUDGETS()
    setBudgets(response?.data)
  }

  const makeNewCostMdoal = () => {
    toggleModal(true)
  }

  const makeNewCost = async () => {
    const datas = `${date?.year}/${date?.month}/${date?.day}`
    const finalDate = moment(datas, 'jYYYY/jM/jD')

    const bodyData = {
      price,
      description,
      due_at: finalDate._i.slice(0, -3),
      budget_id: selectedItem,
    }

    const apiConf = { body: bodyData, id: residenceId }
    const inputsAreValid = price && description?.length && finalDate && selectedItem
    if (inputsAreValid) postRequest(apiConf)
  }

  const postRequest = async (apiConf) => {
    const response = await Service.POST_MAKE_COST(apiConf)
    if (response?.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response?.message}`,
        })
      )
      toggleModal(false)
      callManagerRequest()
    } else
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response?.message}`,
        })
      )
  }

  return (
    <div className='costs'>
      {userType === 'manager' ? <ManagerHeader /> : <ResidentHeader />}
      <div className='costs__header'>
        <span>هزینه ها</span>
        {userType === 'manager' && (
          <Button
            handler={makeNewCostMdoal}
            height='30px'
            label='افزودن هزینه یا درآمد'
            minWidth='160px'
            type='primary'
          />
        )}
      </div>
      <div className='costs__body'>
        <div className='costs__filter'>
          <FontAwesomeIcon color='#999999' icon={faFilter} style={{ marginBottom: '5px' }} />
          <div className='costs__select'>شهریور ماه</div>
        </div>
        <BuildingCostsList data={data} />
      </div>
      <Modal name={BUILDING_COSTS_MODAL} title='افزودن هزینه یا درآمد' toggle={toggleModal}>
        <div className='costs__modal'>
          <div>
            <span>تاریخ:</span>
            <div className='costs__date'>
              <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
              <DatePicker
                shouldHighlightWeekends
                inputPlaceholder='تاریخ انتشار'
                locale='fa'
                value={date}
                onChange={setDate}
              />
            </div>
          </div>
          <div className='costs__selectBox'>
            <span>نوع:</span>
            <SelectBox
              defaultTile='انتخاب هزینه'
              handleSelectedItem={(id) => setSelectedItem(id)}
              items={budgets}
              type='input'
              width='100%'
            />
          </div>
          <Input
            handler={(e) => setPrice(e.target.value)}
            label='مبلغ'
            name='buildingName'
            placeholder='مبلغ'
            tag='input'
            type='number'
            value={price}
          />
          <Input
            handler={(e) => setDescription(e.target.value)}
            name='buildingAddress'
            placeholder='توضیحات'
            tag='textArea'
            type='text-area'
            value={description}
          />
          <Button handler={makeNewCost} height='35px' label='افزودن هزینه' minWidth='130px' type='primary' />
        </div>
      </Modal>
    </div>
  )
}

export default BuildingCosts
