import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { faTimesCircle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Service from '../../js/Api/Service'

import Modal from '../Modal/ModalPage'
import SelectBox from '../selectBox'

import Input from '../input/Input'
import { useModal } from '../../js/hooks/useModal'

import './styles.scss'
import { persianDate, persianTime } from '../../js/utils/moment'
import { setNotif } from '../../js/redux/actions/SharedActions'

const icons = {
  waiting_for_manager: { icon: faSpinner, color: '#FED330', size: '2x' },
  waiting_for_payment: { icon: faSpinner, color: '#999999', size: '2x' },
  disapproved: { icon: faTimesCircle, color: '#FC5C65', size: '2x' },
  approved: { icon: faCheckCircle, color: '#26DE81', size: '2x' },
}
//TODO put the texts into the language file
const items = [
  { id: 1, title: 'در انتظار تایید مدیر', status: 'waiting_for_manager' },
  { id: 2, title: 'در انتظار پرداخت هزینه', status: 'waiting_for_payment' },
  { id: 3, title: 'تایید شده', status: 'approved' },
  { id: 4, title: 'عدم تایید', status: 'disapproved' },
]

const RequestCard = ({
  id,
  user,
  facility,
  status,
  request_time_from,
  request_time_to,
  reason_of_disapproval,
  price,
  description,
  fetchRequestList,
}) => {
  const requestModalName = 'manager-facilities-modal'
  const toggleModal = useModal(requestModalName)

  const dispatch = useDispatch()

  const shared = useSelector((state) => state.shared)

  const [newStatus, setNewStatus] = useState('')
  const [detail, setDetail] = useState('')
  const [amount, setAmount] = useState('')

  async function postNewRequest(body) {
    const response = await Service.POST_MANAGER_SET_STATUS({ id: shared.residence_id, body })
    if (response.status === 200) {
      dispatch(setNotif({ type: 'success', message: `${response.message}` }))
      fetchRequestList()
      toggleModal(false)
    }
  }

  const onSubmit = () => {
    const body = {
      id,
      price: amount,
      status: items.find((item) => item.id === newStatus).status,
      reason_of_disapproval: detail,
    }
    postNewRequest(body)
  }

  const onChangeAmount = (e) => {
    setAmount(e.target.value)
  }
  const onChangeStatus = (status) => {
    setNewStatus(status)
  }
  const onChangeDetail = (e) => {
    setDetail(e.target.value)
  }

  useEffect(() => {
    setAmount(price)
  }, [price])

  const fromDate = persianDate(request_time_from)
  const toDate = persianDate(request_time_to)

  return (
    <div className='managerFacilitiesCard'>
      <div className='managerFacilitiesCard__user'>
        {/* <img alt='profile' src={user.iabn} /> */}
        {user && (
          <div className='managerFacilitiesCard__userInfo'>
            {!!user.first_name && !!user.first_name && <span>{`${user.first_name} ${user.last_name}`}</span>}
            <span>{user.unit?.title || ''}</span>
          </div>
        )}
      </div>
      <div className='managerFacilitiesCard__facility'>
        <span>{facility.title}</span>
        <div className='managerFacilitiesCard__status'>
          <FontAwesomeIcon {...icons[status]} />
          <span className={`managerFacilitiesCard__facility--${status}`}>
            {items.find((item) => item.status === status).title}
          </span>
        </div>
      </div>
      {fromDate === toDate ? (
        <>
          <div className={`managerFacilitiesCard__from ${status}`}>
            <span>{persianDate(request_time_from)}</span>
          </div>
          <div className={`managerFacilitiesCard__to ${status}`}>
            <span>
              <span>از ساعت</span>
              {/*TODO put the texts into the language file*/}

              <span>{persianTime(request_time_from)}</span>
              <span>تا</span>
              <span>{persianTime(request_time_to)}</span>
            </span>
            <span onClick={() => toggleModal(true)}>مشاهده درخواست</span>
              {/*TODO put the texts into the language file*/}

          </div>
        </>
      ) : (
        <>
          <div className={`managerFacilitiesCard__from ${status}`}>
            <span>{persianDate(request_time_from)}</span>
              {/*TODO put the texts into the language file*/}
            <span>- ساعت {persianTime(request_time_from)}</span>
          </div>
          <div className={`managerFacilitiesCard__to ${status}`}>
            <span>
              <span>{persianDate(request_time_to)}</span>
              {/*TODO put the texts into the language file*/}
              <span>- ساعت {persianTime(request_time_to)}</span>
            </span>
              {/*TODO put the texts into the language file*/}
              <span onClick={() => toggleModal(true)}>مشاهده درخواست</span>
          </div>
        </>
      )}
        {/*TODO put the texts into the language file*/}
        <Modal name={requestModalName} title='درخواست استفاده' toggle={toggleModal}>
        <div className='managerFacilitiesCard__user'>
          {/* <img alt='profile' src={avatar} /> */}
          {user && (
            <div className='managerFacilitiesCard__userInfo'>
              {!!user.first_name && !!user.first_name && <span>{`${user.first_name} ${user.last_name}`}</span>}
              <span>{user.unit?.title || ''}</span>
            </div>
          )}
        </div>
        <div className='managerFacilitiesCard__facility'>
          <span>{facility.title}</span>
        </div>
        {fromDate === toDate ? (
          <>
            <div className={`managerFacilitiesCard__from ${status}`}>
              <span>{persianDate(request_time_from)}</span>
            </div>
            <div className={`managerFacilitiesCard__from ${status}`}>
                {/*TODO put the texts into the language file*/}
              <span>از ساعت</span>
              <span>{persianTime(request_time_from)}</span>
              <span>تا</span>
              <span>{persianTime(request_time_to)}</span>
            </div>
          </>
        ) : (
          <>
            <div className={`managerFacilitiesCard__from ${status}`}>
              <span>{persianDate(request_time_from)}</span>
                {/*TODO put the texts into the language file*/}
                <span>- ساعت {persianTime(request_time_from)}</span>
            </div>
            <div className={`managerFacilitiesCard__from ${status}`}>
              <span>{persianDate(request_time_to)}</span>
                {/*//TODO put the texts into the language file*/}
                <span>- ساعت {persianTime(request_time_to)}</span>
            </div>
          </>
        )}
        <div className='managerFacilitiesCard__subject'>{description}</div>
        <div className='managerFacilitiesCard__row'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            {/*//TODO put the texts into the language file*/}
            <span>هزینه:</span>
          </span>
          <Input handler={onChangeAmount} name='amount' placeholder='مبلغ' value={amount} />
          <span>تومان</span>
        </div>
        <div className='managerFacilitiesCard__row'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            {/*//TODO put the texts into the language file*/}
            <span>وضعیت:</span>
          </span>
          <SelectBox
            direction='up'
            handleSelectedItem={(item) => onChangeStatus(item)}
            items={items}
            type='input'
            width='190px'
          />
          <span></span>
        </div>
        {newStatus === 4 && (
          <div className='managerFacilitiesCard__detail'>
              {/*//TODO put the texts into the language file*/}
              <Input
              handler={onChangeDetail}
              name='detail'
              placeholder='علت عدم تایید'
              tag='textArea'
              value={reason_of_disapproval}
            />
          </div>
        )}
            {/*//TODO put the texts into the language file*/}
            <div className='managerFacilitiesCard__button' onClick={onSubmit}>
          ثبت
        </div>
      </Modal>
    </div>
  )
}

export default RequestCard
