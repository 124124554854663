/* eslint-disable react/no-multi-comp */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import './switchBetweenBuildings.scss'
import Header from '../../../components/header/Header'

import Service from '../../Api/Service'

import * as SharedActions from '../../redux/actions/SharedActions'

import BG1 from '../../../assets/img/bg.jpg'
import BG2 from '../../../assets/img/bg2.jpg'

export default function SwitchBetweenBuildings(props) {
  const [buildings, setBuildings] = useState([])

  useEffect(() => {
    callSwitchOptions()
  }, [])

  // Redux actions
  const dispatch = useDispatch()

  const callSwitchOptions = async () => {
    const response = await Service.GET_USER_RESIDENCES()
    if (response.status === 200) {
      setBuildings(response.data)
    }
  }

  const callConfirmResident = async (item) => {
    await Service.POST_UNIT_CONFIRM({ id: item.unit.id })
    // if (response?.status === 200) {
    //   dispatch(
    //     SharedActions.setNotif({
    //       type: 'success',
    //       message: `${response.message}`,
    //     })
    //   )
    props.history.push('/dashboard')
    // } else {
    //   dispatch(
    //     SharedActions.setNotif({
    //       type: 'danger',
    //       message: `${response?.message}`,
    //     })
    //   )
    // }
  }

  const handleResidenceSelection = (item, userType) => {
    const residence_id = userType === 'resident' ? item?.residence.id : item.id
    dispatch(SharedActions.setUserType({ residence_id, userType }))
    dispatch(SharedActions.setUnitData({ unitData: item?.unit || item }))

    localStorage.setItem('title', item.name || item?.residence?.name)

    if (userType === 'resident') callConfirmResident(item)
    else props.history.push('/dashboard')
  }

  return (
    <div className='switch-building'>
      <Header />
      <div className='switch-building__container'>
        <div className='switch-building__title'>
          <span>انتخاب ساختمان</span>
        </div>
        {buildings?.with_manager_role?.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <SwitchCard data={item} handleResidenceSelection={handleResidenceSelection} type='manager' />
            </React.Fragment>
          )
        })}

        {buildings?.with_resident_role?.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <SwitchCard data={item} handleResidenceSelection={handleResidenceSelection} type='resident' />
            </React.Fragment>
          )
        })}

        <button className='switch-building__button' onClick={() => props.history.push('/add-building')}>
          <span>افزودن ساختمان جدید</span>
        </button>
      </div>
    </div>
  )
}

const SwitchCard = (props) => {
  const { handleResidenceSelection, data, type } = props

  const name = type === 'resident' ? data?.residence.name : data.name
  const title = type === 'resident' ? 'ساکن ساختمان' : 'مدیر ساختمان'

  return (
    <div className='switch-building__user'>
      <div className='switch-building__user-info'>
        <div className='switch-building__user-info__image'>
          <img alt='background' src={BG1}></img>
        </div>
        <div className='switch-building__user-info__desc'>
          <span className='switch-building__user-info__desc--title'>{title}</span>
          <span className='switch-building__user-info__desc--name'>{name}</span>
          {type === 'resident' && <span className='switch-building__user-info__desc--unit'>{data?.unit?.title}</span>}
        </div>
      </div>
      <button className='switch-building__user-btn' onClick={() => handleResidenceSelection(data, type)}>
        انتخاب
      </button>
    </div>
  )
}
