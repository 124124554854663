import React, { useState } from 'react'

import SubmitAd from '../submitAd'
import ResidentHeader from '../residentHeader'

import './styles.scss'

const ResidentAdvertising = () => {
  const [tab, setTab] = useState(0)

  return (
    <>
      <ResidentHeader />
      <div className='advertisingPage'>
        <div className='advertisingPage__header'>
          <span>تبلیغات</span>
        </div>
        <div className='advertisingPage__body'>
          <div className='advertisingPage__tabs'>
{/*              //TODO put the texts into the language file*/}

            <button
              className={`advertisingPage__tab ${tab === 0 && 'advertisingPage__tab--active'}`}
              type='button'
              onClick={() => setTab(0)}
            >
              سفارش آگهی
            </button>
            <button
              className={`advertisingPage__tab ${tab === 1 && 'advertisingPage__tab--active'}`}
              type='button'
              onClick={() => setTab(1)}
            >
              لیست آگهی‌ها
            </button>
          </div>
          {tab === 0 ? <SubmitAd /> : null}
        </div>
        <div className='advertisingPage__advertise'>
          <span>تبلیغات</span>
          <span>Ad</span>
        </div>
      </div>
    </>
  )
}

export default ResidentAdvertising
