/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */

import React, { lazy, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as SharedActions from '../../redux/actions/SharedActions'
import * as AuthActions from '../../redux/actions/AuthActions'

import LOGO from '../../../assets/img/logo_white.svg'
import GOOGLE from '../../../assets/img/facebook-login.svg'
import FACEBOOK from '../../../assets/img/google-login.svg'

import Service from '../../Api/Service'

import './Login.scss'

const Button = lazy(() => import(/* webpackChunkName: "Button" */ '../../../components/button/Button'))
const Input = lazy(() => import(/* webpackChunkName: "Button" */ '../../../components/input/Input'))

export default function Login(props) {
  const { t } = useTranslation()
  const [phoneNumber, setphoneNumber] = useState('')

  // Redux actions
  const dispatch = useDispatch()

  const tellNumIsValid = phoneNumber.toString().length === 11

  const loginConfirmHandler = async () => {
    const response = await Service.POST_SendCode({ type: 'login',channel: 'mail', destination: phoneNumber })
    if (response.status === 200) {
      props.history.push({
        pathname: '/otp-login',
        state: {
          type: 'login',
          telNumber: phoneNumber,
        },
      })
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  return (
    <div className='login'>
      <div className='login-box'>
        <div className='login__header'>
          <img alt='logo' src={LOGO} />
        </div>

        <div className='login__main'>
          <h2>{t('users-sign-in')}</h2>
          <Input
            handler={(e) => setphoneNumber(e.target.value)}
            label={t('cell-phone')}
            name='user-name'
            placeholder={t('cell-phone-number')}
            type='number'
          />

          <Button
            disabled={!tellNumIsValid}
            handler={loginConfirmHandler}
            height='35px'
            label={t('sign-in-otp')}
            minWidth='50px'
            type={tellNumIsValid ? 'primary' : 'disabled'}
            width='100%'
          />
        </div>

        <h2>
          {t('i-was-not-a-member')} <a onClick={() => props.history.push('/signup')}>{t('user-registration')}</a>
        </h2>
      </div>
    </div>
  )
}
