import React, { memo } from 'react'
import './Button.scss'

const Button = (props) => {
  const {
    label,
    loading,
    width,
    height,
    minWidth = '100px',
    fontSize,
    handler,
    type,
    icon,
    disabled,
    isActive,
    className,
    leftIcon = false,
    style,
    id,
  } = props

  const handleClick = () => {
    if (!loading) {
      handler && handler()
    }
  }

  /**
   *
   * default(login btn)
   * bordered (filter button)
   *
   */
  let finalClassName = `${className || ''} IB-button`
  if (type === 'danger') finalClassName += ' IB-button--danger'
  if (type === 'accept') finalClassName += ' IB-button--accept'
  if (type === 'reject') finalClassName += ' IB-button--reject'
  if (type === 'success') finalClassName += ' IB-button--success'
  if (type === 'bordered') finalClassName += ' IB-button--bordered'
  if (type === 'disabled') finalClassName += ' IB-button--disabled'
  if (type === 'accept-white-bg') finalClassName += ' IB-button--accept-white-bg'
  if (type === 'primary-white-bg') finalClassName += ` IB-button--primary-white-bg`
  if (type === 'solid') finalClassName += ` IB-button--solid IB-button--solid-${isActive}`
  if (type === 'elevated') finalClassName += ` IB-button--elevated IB-button--elevated-${isActive}`
  if (type === 'normal-text') finalClassName += '  IB-button--normal-text'
  if (type === 'gray') finalClassName += '  IB-button--gray'

  return (
    <>
      <button
        className={finalClassName}
        disabled={disabled}
        id={id}
        style={{
          width,
          minWidth,
          height,
          ...style,
        }}
        type='submit'
        onClick={handleClick}
      >
        <>
          {icon && !leftIcon && (
            <div
              alt='icon'
              className='IB-button__icon'
              style={{
                WebkitMaskBoxImage: `url(${icon})`,
              }}
            />
          )}

          <p className='IB-button__label' style={{ fontSize }}>
            {label}
          </p>

          {icon && leftIcon && (
            <div
              alt='icon'
              className='IB-button__icon IB-button__icon--left'
              style={{
                WebkitMaskBoxImage: `url(${icon})`,
              }}
            />
          )}
        </>
      </button>
    </>
  )
}

export default memo(Button)
