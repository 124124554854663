import FACILITIES_ACTION_TYPES from '../constants/FacilitiesConstants'
import Service from '../../Api/Service'

export const setAllFacilities = (list) => ({ type: FACILITIES_ACTION_TYPES.SET_ALL_FACILITIES, list })
export const setFacilitiesRequest = (list) => ({ type: FACILITIES_ACTION_TYPES.SET_ALL_FACILITIES_REQUEST, list })
export const clearRequests = () => ({ type: FACILITIES_ACTION_TYPES.CLEAR_REQUESTS })
export const setFacilityTerms = (terms) => ({ type: FACILITIES_ACTION_TYPES.SET_TERMS, terms })

export const getFacilityTerms = () => async (dispatch) => {
  const response = await Service.GET_FACILITY_TERMS()
  dispatch(setFacilityTerms(response.message))
}
