/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { lazy, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as SharedActions from '../../redux/actions/SharedActions'

import LOGO from '../../../assets/img/logo_white.svg'
import GOOGLE from '../../../assets/img/facebook-login.svg'
import FACEBOOK from '../../../assets/img/google-login.svg'
import MANAGER from '../../../assets/img/icons/manager.svg'
import RESIDENT from '../../../assets/img/icons/resident.svg'

import Service from '../../Api/Service'

import './signup.scss'

const Input = lazy(() => import('../../../components/input/Input'))
const Button = lazy(() => import('../../../components/button/Button'))
const ItemSelect = lazy(() => import('../../../components/itemsSelect/ItemsSelect'))

export default function SignUp(props) {
  const { t } = useTranslation()

  // Local States
  const [activeFilterId, setActiveFilterId] = useState(0)
  const [phoneNumber, setphoneNumber] = useState('')
  const [Name, setName] = useState(null)
  const [lastName, setLastName] = useState(null)

  // Redux actions
  const dispatch = useDispatch()

  const tellNumIsValid = phoneNumber.toString().length === 11 && Name && lastName

  const typeOfusers = [
    { id: 1, title: t('apartment-resident'), icon: RESIDENT },
    { id: 2, title: t('apartment-manager'), icon: MANAGER },
  ]

  const changeFilterItem = ({ iterationId: itemId }) => {
    setActiveFilterId(itemId)
  }

  const handleSignupConfirm = async () => {
    if (!tellNumIsValid) return
    const response = await Service.POST_SendCode({ type: 'register', mobile_number: phoneNumber })
    if (response.status === 200) {
      props.history.push({
        pathname: '/otp-login',
        state: {
          first_name: Name,
          type: 'register',
          last_name: lastName,
          telNumber: phoneNumber,
          typeOfUser: activeFilterId === 0 ? 'resident' : 'manager',
        },
      })
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  return (
    <div className='signup'>
      <div className='signup-box'>
        <div className='signup__header'>
          <img alt='logo' src={LOGO} />
        </div>

        <div className='signup__main'>
          <h2>{t('membership')}</h2>
          <Input
            handler={(e) => setName(e.target.value)}
            label={t('name')}
            name='firs name'
            placeholder={t('name')}
            type='text'
          />
          <Input
            handler={(e) => setLastName(e.target.value)}
            label={t('last-name')}
            name='last name'
            placeholder={t('last-name')}
            type='text'
          />
          <Input
            handler={(e) => setphoneNumber(e.target.value)}
            label={t('cell-phone')}
            name='cell-phone'
            placeholder={t('cell-phone-number')}
            type='number'
          />

          <h3>{t('select-user-type')}</h3>
          <div className='signup__selection'>
            {typeOfusers.map((filter, index) => {
              return (
                <React.Fragment key={filter.id}>
                  <ItemSelect
                    activeItemId={activeFilterId}
                    handler={changeFilterItem}
                    height='90px'
                    img={filter.icon}
                    iterationId={index}
                    label={filter.title}
                    type='filter'
                  />
                </React.Fragment>
              )
            })}
          </div>

          <Button
            disable={!tellNumIsValid}
            handler={handleSignupConfirm}
            height='35px'
            label={t('membership')}
            minWidth='50px'
            type={tellNumIsValid ? 'primary' : 'disabled'}
            width='100%'
          />
        </div>

        <h2>
          {t('i-was-previously-a-member')} <a onClick={() => props.history.push('/login')}>{t('users-sign-in')}</a>
        </h2>
      </div>
    </div>
  )
}
