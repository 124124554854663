/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import React from 'react'

import { faChart, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../components/button/Button'

import PEN from '../../../assets/img/icons/pen.svg'
import FILE from '../../../assets/img/icons/file.svg'
import CARD from '../../../assets/img/icons/card.svg'
import HELP from '../../../assets/img/icons/help.svg'
import CHART from '../../../assets/img/icons/chart.svg'
import MONEY from '../../../assets/img/icons/money.svg'
import PEOPLE from '../../../assets/img/icons/people.svg'
import NOTIF from '../../../assets/img/icons/notif.svg'
import ADVERTISEMENT from '../../../assets/img/icons/advertisement.svg'

import ManagerHeader from '../../../components/managerHeader'

import './manager.scss'

import CITY from '../../../assets/img/city.jpg'

export default function Resident(props) {
  return (
    <div className='manager'>
      <div className='manager__box'>
        <ManagerHeader />

        <div className='manager__payment'>
          <div>
            {/*TODO put the texts into the language file*/}
            <span>پرداخت شارژ این ماه</span>
            <span> تعداد کل واحد های ساختمان: 12</span>
          </div>
          <div>
            <div>
              <span>9 واحد </span>
            </div>
          </div>
        </div>

        <div className='manager__price'>
          {/*TODO put the texts into the language file*/}
          <span>مانده شارژ پرداخت نشده:</span>
          <span>
            <b>{props?.profit}</b> تومان
          </span>
        </div>
        <hr />
        <div className='manager__statistics'>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={PEN} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>اطلاعات ساختمان</span>
          </div>
          <div onClick={() => props.history.push('/units-list')}>
            <div>
              <img alt='' src={PEOPLE} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>ساکنان ساختمان</span>
          </div>
          <div onClick={() => props.history.push('/building-costs')}>
            <div>
              <img alt='' src={HELP} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>هزینه ها</span>
          </div>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={HELP} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>شارژ ساختمان</span>
          </div>
          <div onClick={() => props.history.push('/financial-saving')}>
            <div>
              <img alt='' src={MONEY} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>صندوق ساختمان</span>
          </div>
          <div onClick={() => props.history.push('/announcements')}>
            <div>
              <img alt='announcements' src={NOTIF} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>اعلانات</span>
          </div>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={CHART} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>گزارش فعالیت</span>
          </div>
          <div onClick={() => props.history.push('/manager-facilities')}>
            <div>
              <img alt='facilities' src={CARD} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>امکانات اشتراکی</span>
          </div>
        </div>
        <hr />
        <div className='manager__statistics'>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={FILE} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>تبلیغات</span>
          </div>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={ADVERTISEMENT} />
            </div>
            {/*TODO put the texts into the language file*/}
            <span>فروش و اجاره</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export function CostDetails(props) {
  const { color } = props
  return (
    <div className='box'>
      <div style={{ backgroundColor: color }}></div>
      {/*TODO put the texts into the language file*/}
      <span>هزینه آب مشترک</span>
    </div>
  )
}
