import moment from 'moment-jalaali'

const dict = {
  Farvardin: 'فروردین',
  Ordibehesht: 'اردیبهشت',
  Khordad: 'خرداد',
  Tir: 'تیر',
  Mordad: 'مرداد',
  Shahrivar: 'شهریور',
  Mehr: 'مهر',
  Aban: 'آبان',
  Azar: 'آذر',
  Day: 'دی',
  Bahman: 'بهمن',
  Esfand: 'اسفند',
}
export const persianDate = (date) => {
  const instance = moment(date).locale('fa')
  const day = instance.format('jD')
  const month = instance.format('jMMMM')
  const year = instance.format('jYYYY')
  return `${day} ${dict[month]} ${year}`
}

export const persianTime = (time) => new Date(time).toLocaleTimeString('fa-IR').split(/:.{2}$/g)[0]
