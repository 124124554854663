import React, { useCallback, useEffect, useRef } from 'react'

export default function Content({ onClose, children }) {
  const ref = useRef(null)

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }, [])

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!ref.current.contains(e.target)) {
        onClose()
      }
    },
    [ref.current]
  )

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [])

  return <div ref={ref}>{children}</div>
}
