/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Service from '../../Api/Service'

import Manager from '../manager/Manager'
import Resident from '../resident/Resident'
import Header from '../../../components/header/Header'

import './dashboard.scss'

export default function Dashboard(props) {
  const [state, setstate] = useState(0)

  const userType = useSelector((state) => state.shared.userType)
  const residenceId = useSelector((state) => state.shared.residence_id)
  const unitId = useSelector((state) => state.shared.unitData.id)

  useEffect(() => {
    callProfitApi()
  }, [])

  const callProfitApi = async () => {
    const response = await Service.GET_PROFITS({ id: residenceId })
    if (response.status === 200) {
      setstate(response?.data?.profit)
    }
  }

  const { history } = props
  return (
    <div className='dashboard'>
      <Header />
      {userType === 'manager' ? <Manager history={history} profit={state} /> : <Resident history={history} />}
      <button onClick={() => history.push('/add-building')}>
        {/*TODO put the texts into the language file*/}
        <span>افزودن ساختمان جدید</span>
      </button>
    </div>
  )
}
