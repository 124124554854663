export default {
  blogPosts: [],
  auth: {
    isAuth: false,
    userInformation: {},
  },
  shared: {
    userType: null,
    residence_id: null,
    notif: {
      isEnabled: false,
      type: null,
      message: null,
    },
  },
  facilities: {
    all: [],
    requested: [],
    terms: '',
  },
}
