import React from 'react'

import Header from '../components/header/Header'

import '../assets/styles/base-hub.scss'

const DashboardLayout = ({ children }) => {
  return (
    <section className='dashboard-layout'>
      <Header />
      <div className='content'>{children}</div>
    </section>
  )
}

export default DashboardLayout
