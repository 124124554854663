import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import ResidentAdvertising from '../../../components/residentAdvertising'

import './styles.scss'

const Advertising = () => {
  const routeMatch = useRouteMatch()

  return routeMatch.params.role === 'manager' ? null : <ResidentAdvertising />
}

export default Advertising
