/* eslint-disable no-restricted-globals */
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'

import routes from './js/routes'
import TitleComponent from './components/titleComponent/TitleComponent'
import Toast from './components/toast/Toast'
import Guard from './components/auth/Guard'
import AddToHomeScreen from './components/addToHome'

import './app.scss'

function App(props) {
  const Auth = useSelector((state) => state.auth)

  if (Auth.isAuth) Cookies.set('token', Auth.userToken)

  return (
    <div className='App'>
      <Router basename={process.env.REACT_APP_BASENAME || ''}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                component={(props) => {
                  return (
                    <route.layout {...props}>
                      <Suspense fallback={null}>
                        <TitleComponent />
                        <Toast />
                        <AddToHomeScreen />
                        <Guard route={route}>
                          <route.component {...props} />
                        </Guard>
                      </Suspense>
                    </route.layout>
                  )
                }}
                exact={route.exact}
                key={`${route + index}`}
                path={route.path}
              />
            )
          })}
        </Switch>
      </Router>
    </div>
  )
}

export default App
