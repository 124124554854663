/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ManagerAnouncemenets from './ManagerAnouncemenets'
import ResidentAnouncemenets from './ResidentAnouncemenets'

import './styles.scss'
import Service from '../../Api/Service'

const Anouncemenets = () => {
  const [managerData, setManagerData] = useState([])
  const userType = useSelector((state) => state.shared.userType)
  const residence_id = useSelector((state) => state.shared.residence_id)

  useEffect(() => {
    callManagerRequest()
  }, [])

  const callManagerRequest = async () => {
    const managerData = await Service.GET_USER_ANNOUNCEMENTS({ id: residence_id })
    setManagerData(managerData)
  }

  return userType === 'manager' ? (
    <ManagerAnouncemenets data={managerData} handleNewAnouncemenets={callManagerRequest} residenceId={residence_id} />
  ) : (
    <ResidentAnouncemenets data={managerData} />
  )
}

export default Anouncemenets
