/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment-jalaali'

import DatePicker from 'react-modern-calendar-datepicker'
import * as SharedActions from '../../redux/actions/SharedActions'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'

import ManagerHeader from '../../../components/managerHeader'
import Button from '../../../components/button/Button'

import Modal from '../../../components/Modal/ModalPage'
import Input from '../../../components/input/Input'

import { useModal } from '../../hooks/useModal'

import Service from '../../Api/Service'

import './styles.scss'

export default function ManagerAnouncemenets(props) {
  const ANNOUNCEMENT_MODAL = 'ANNOUNCEMENT_MODAL'

  const { data, residenceId } = props
  const [date, setDate] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [modalType, setModalType] = useState(null)
  const [acmItem, setAcmItem] = useState(null)

  // Redux actions
  const dispatch = useDispatch()

  const toggleModal = useModal(ANNOUNCEMENT_MODAL)

  const makeNewAnnouncement = () => {
    setModalType('CREATE')
    toggleModal(true)
  }

  const addNewAnnouncement = async () => {
    const datas = `${date?.year}/${date?.month}/${date?.day}`
    const finalDate = moment(datas, 'jYYYY/jM/jD')

    const bodyData = {
      publish_date: finalDate.format('YYYY/M/D'),
      title,
      description,
      residence_id: residenceId,
    }

    const apiConf = { body: bodyData }
    const response = await Service.POST_NEW_ANNOUNCEMENTS(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      setDate(null)
      setTitle('')
      setDescription('')
      props.handleNewAnouncemenets()
    } else
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
    toggleModal(false)
  }

  const removeItem = async (id) => {
    const response = await Service.DELETE_ANNOUNCEMENTS({ id })
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
    }
    props.handleNewAnouncemenets()
  }

  const editdata = (item) => {
    setModalType('EDIT')
    toggleModal(true)
    setTitle(item.title)
    setDescription(item.description)
    setAcmItem(item)
  }

  const callEditAnnouncementApi = async () => {
    const datas = `${date?.year}/${date?.month}/${date?.day}`
    const finalDate = moment(datas, 'jYYYY/jM/jD')
    const { id } = acmItem

    const bodyData = {
      publish_date: finalDate.format('YYYY/M/D'),
      title,
      description,
      residence_id: residenceId,
    }

    const apiConf = { body: bodyData, id }

    const response = await Service.PATCH_ANNOUNCEMENTS(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      toggleModal(false)
    }
  }

  return (
    <div className='anouncemenets'>
      <ManagerHeader />
      <div className='anouncemenets__header'>
        <span>اعلانات ساختمان</span>
        <Button handler={makeNewAnnouncement} height='35px' label='ساخت اطلاعیه جدید' minWidth='130px' type='primary' />
      </div>
      <div className='anouncemenets__manager'>
        {data &&
          data?.map((item) => (
            <div key={item.id}>
              <FontAwesomeIcon icon={faBullhorn} />
              <span>{item.title}</span>

              <p>{item.description}</p>
              <div className='anouncemenets__actions'>
                <Button
                  handler={() => editdata(item)}
                  height='35px'
                  label='ویرایش'
                  minWidth='50px'
                  type='primary'
                  width='100px'
                />
                <Button
                  handler={() => removeItem(item.id)}
                  height='35px'
                  label='حذف اطلاعیه'
                  minWidth='50px'
                  type='reject'
                  width='100px'
                />
              </div>
            </div>
          ))}
      </div>

      <Modal name={ANNOUNCEMENT_MODAL} title='ساخت اطلاعیه جدید' toggle={toggleModal}>
        <div className='anouncemenets__modal'>
          <div>
            <span>تاریخ:</span>
            <div className='anouncemenets__date'>
              <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
              <DatePicker
                shouldHighlightWeekends
                inputPlaceholder='تاریخ انتشار'
                locale='fa'
                value={date}
                onChange={setDate}
              />
            </div>
          </div>
          <Input
            handler={(e) => setTitle(e.target.value)}
            label='عنوان'
            name='buildingName'
            placeholder='عوان اطلاعیه را انتخاب کنید'
            tag='input'
            type='text'
            value={title}
          />

          <Input
            handler={(e) => setDescription(e.target.value)}
            name='buildingAddress'
            placeholder='متن اطلاعیه'
            tag='textArea'
            type='text-area'
            value={description}
          />

          <Button
            handler={modalType !== 'CREATE' ? callEditAnnouncementApi : addNewAnnouncement}
            height='35px'
            label={modalType !== 'CREATE' ? 'اعمال ویرایش' : 'افزودن '}
            minWidth='130px'
            type='primary'
          />
        </div>
      </Modal>
    </div>
  )
}
