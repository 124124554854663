/* eslint-disable react/display-name */
import React from 'react'
// Layout Types
import { DefaultLayout, DashboardLayout /* , EmptyLayout */ } from '../layouts'

// Route containers
import Otp from './pages/otp/Otp'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import SignUp from './pages/signUp/SignUp'
import Landing from './pages/landing/Landing'
// import UserType from './pages/signUp/userType'
import Facilities from './pages/facilities'
import Advertising from './pages/advertising'
import UnitsList from './pages/addUnits/UnitsList'
import Dashboard from './pages/dashboard/Dashboard'
import EditProfile from './pages/dashboard/EditProfile'
import AddBuilding from './pages/addBuilding/AddBuilding'
import Anouncemenets from './pages/anouncemenets/Anouncemenets'
import RegistrationCode from './pages/registrationCode/RegistrationCode'
// import AddBuildingNumber from './pages/registrationCode/AddBuildingNumber'
import SwitchBetweenBuildings from './pages/switchBetweenBuildings/SwitchBetweenBuildings'
import BuildingCosts from './pages/buildingCosts/BuildingCosts'
// import FinancialSaving from './pages/financial-2/FinancialSaving'
import FinancialSaving from './pages/financialSaving'
import SuccessPage from './pages/successPage/SuccessPage'

export default [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: Landing,
  },

  // Authorization
  {
    path: '/home',
    layout: DefaultLayout,
    exact: true,
    component: Home,
    guest: true,
  },
  {
    path: '/login',
    layout: DefaultLayout,
    exact: true,
    component: Login,
    guest: true,
  },
  {
    path: '/signup',
    layout: DefaultLayout,
    exact: true,
    component: SignUp,
    guest: true,
  },
  {
    path: '/registration-code',
    layout: DefaultLayout,
    exact: true,
    component: RegistrationCode,
    protected: true,
  },
  // {
  //   path: '/registration-code-number',
  //   layout: DefaultLayout,
  //   exact: true,
  //   component: AddBuildingNumber,
  //   protected: true,
  // },
  {
    path: '/otp-login',
    layout: DefaultLayout,
    exact: true,
    component: Otp,
    guest: true,
  },
  {
    path: '/add-building',
    layout: DefaultLayout,
    exact: true,
    component: AddBuilding,
    protected: true,
  },
  {
    path: '/dashboard',
    layout: DefaultLayout,
    exact: true,
    component: Dashboard,
    protected: true,
  },
  {
    path: '/edit-profile',
    layout: DefaultLayout,
    exact: true,
    component: EditProfile,
    protected: true,
  },
  {
    path: '/switch-building',
    layout: DefaultLayout,
    exact: true,
    component: SwitchBetweenBuildings,
    protected: true,
  },
  {
    path: '/announcements',
    layout: DashboardLayout,
    exact: true,
    component: Anouncemenets,
    protected: true,
  },
  {
    path: '/units-list',
    layout: DefaultLayout,
    exact: true,
    component: UnitsList,
    protected: true,
  },
  // {
  //   path: '/user-type',
  //   layout: DefaultLayout,
  //   exact: true,
  //   component: UserType,
  //   protected: true,
  // },

  // {
  //   path: '/manager',
  //   layout: DefaultLayout,
  //   exact: true,
  //   component: Manager,
  // },
  // {
  //   path: "/register",
  //   layout: EmptyLayout,
  //   exact: true,
  //   component: Register
  // },

  // Pages
  {
    path: '/landing',
    layout: DefaultLayout,
    exact: true,
    component: Landing,
  },
  {
    path: '/en/landing',
    layout: DefaultLayout,
    exact: true,
    component: Landing,
  },
  {
    path: '/:role-facilities',
    layout: DashboardLayout,
    exact: true,
    protected: true,
    component: Facilities,
  },
  {
    path: '/building-costs',
    layout: DashboardLayout,
    exact: true,
    protected: true,
    component: BuildingCosts,
  },
  {
    path: '/financial-saving',
    layout: DashboardLayout,
    exact: true,
    protected: true,
    component: FinancialSaving,
  },
  {
    path: '/success-payment',
    layout: DashboardLayout,
    exact: true,
    protected: true,
    component: SuccessPage,
  },
  {
    path: '/:role-advertising',
    layout: DashboardLayout,
    exact: true,
    protected: true,
    component: Advertising,
  },
  // {
  //   path: '/financial-saving',
  //   layout: DashboardLayout,
  //   exact: true,
  //   protected: true,
  //   component: FinancialSaving,
  // },
  {
    path: '*',
    layout: ({ children }) => children,
    component: () => <h1 style={{ textAlign: 'center', marginTop: '40%' }}>404|Not Found!</h1>,
  },
]
