/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as SharedActions from '../../redux/actions/SharedActions'

import './AddBuilding.scss'

import Service from '../../Api/Service'

import Header from '../../../components/header/Header'
import Button from '../../../components/button/Button'
import Stepper from '../../../components/stepper/Stepper'
import Input from '../../../components/input/Input'
import NumberInput from '../../../components/input/NumberInput'

export default function AddBuilding(props) {
  const [currentStep, setCurrentStep] = useState(1)
  const [facilities, setFacilities] = useState(null)
  const [chargeRules, setChargeRules] = useState(null)
  const [chargeId, setChargeId] = useState(3)
  const [selectedFacility, setSelectedFacility] = useState([])
  const [userInformation, setUserInformation] = useState({
    buildingName: '',
    buildingAddress: '',
  })
  const [inputsValue, setInputsValue] = useState({
    blocks: 1,
    apartment: 1,
    business: 0,
    period: 1,
  })

  // Redux State
  const userType = useSelector((state) => state.shared.userType)

  // Redux actions
  const dispatch = useDispatch()

  const { blocks, apartment, business, period } = inputsValue
  const { buildingName, buildingAddress } = userInformation

  const handleClick = (clickType) => {
    let newStep = currentStep
    clickType === 'next' ? newStep++ : newStep--

    if (newStep > 0 && newStep <= 3) {
      setCurrentStep(newStep)
    } else if (newStep === 0 && clickType === 'back') {
      props.history.push('/registration-code')
    } else if (newStep > 3 && clickType === 'next') {
      callResidencesApi()
    }
  }

  useEffect(() => {
    callFacilities()
    callChargeRules()
  }, [])

  const numberInputsHandler = (type, name) => {
    let newInputsValue
    if (type === 'inc' && inputsValue[name] >= 0 && inputsValue[name] < 31) {
      newInputsValue = { ...inputsValue, [name]: inputsValue[name] + 1 }
      setInputsValue(newInputsValue)
    } else if (type === 'dec' && inputsValue[name] > 1) {
      newInputsValue = { ...inputsValue, [name]: inputsValue[name] - 1 }
      setInputsValue(newInputsValue)
    }
  }

  const userInformationHandler = (e, name) => {
    let newInformation
    newInformation = { ...userInformation, [name]: e.target.value }
    setUserInformation(newInformation)
  }

  const checkSelectedfacility = (id) => {
    const indexOfId = selectedFacility.indexOf(id)
    const newList = selectedFacility
    indexOfId === -1 ? newList.push(id) : newList.splice(indexOfId, 1)

    setSelectedFacility(newList)
  }

  //  make api call functions  start* //
  const callFacilities = async () => {
    const response = await Service.GET_FACILITIES()
    if (response.status === 200) setFacilities(response.data)
    else console.log(response)
  }

  const callChargeRules = async () => {
    const response = await Service.GET_CHARGE_RULES()
    if (response.status === 200) setChargeRules(response.data)
    else console.log(response)
  }

  const callResidencesApi = async () => {
    const bodyData = {
      name: buildingName,
      address: buildingAddress,
      type: 'building',
      mature_on_month: '10',
      blocks: [apartment],
      charge_rules: [chargeId],
      facilities: selectedFacility,
    }

    const apiConf = { body: bodyData }
    const response = await Service.POST_RESIDENCES(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      dispatch(SharedActions.setUserType({ userType, residence_id: response.data.id }))
      localStorage.setItem('title', buildingName)
      props.history.push('/units-list')
    } else
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
  }
  // * make api call functions end *  //

  const onChangeImage = (e) => {
    console.log(e)
  }

  const stepsArray = ['اطلاعات اولیه', 'روش محاسبه', 'امکانات']

  return (
    <div className='add-building'>
      <Header />
      <div className='add-building__container'>
        <div className='stepper-container-horizontal'>
          <Stepper currentStepNumber={currentStep - 1} direction='horizontal' stepColor='#FFA70B' steps={stepsArray} />
        </div>
        {currentStep === 1 && (
          <div className='add-building__body'>
            <div>
              <Input
                handler={(e, name) => userInformationHandler(e, name)}
                label='نام ساختمان'
                name='buildingName'
                placeholder='یک نام برای ساختمان انتخاب کنید'
                tag='input'
                type='text'
              />
              <Input
                handler={(e, name) => userInformationHandler(e, name)}
                label='آدرس'
                name='buildingAddress'
                placeholder=''
                tag='textArea'
                type='text-area'
              />
            </div>
            <div className='add-building__map'></div>
            <div className='add-building__upload'>
              <label htmlFor='uploading'>تصویر:</label>
              <div name='uploading'>
                <input accept='image/png, image/jpeg' name='buildingImage' type='file' onChange={onChangeImage} />
                <span>+</span>
                <span>upload</span>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className='calculating'>
            <NumberInput
              handleInputValue={(type, name) => console.log('object')}
              label='تعداد بلوک'
              name='blocks'
              value={blocks}
            />

            <div className='calculating__blocks'>
              <span>بلوک 1</span>
              <NumberInput
                handleInputValue={(type, name) => numberInputsHandler(type, name)}
                height='32px'
                label='واحد مسکونی'
                name='apartment'
                value={apartment}
                width='60px'
              />
              <NumberInput
                handleInputValue={(type, name) => numberInputsHandler(type, name)}
                height='32px'
                label='کسب و کار'
                name='business'
                value={business}
                width='60px'
              />
            </div>

            <div className='calculating__type'>
              <span>روش محاسبه : </span>
              <section className='light'>
                {chargeRules?.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <label>
                        <input
                          checked={chargeId === item.id}
                          name='light'
                          type='radio'
                          // onChange={() => setChargeId(item.id)}
                        />
                        <span className='design'></span>
                        <span className='text'>{item.title}</span>
                      </label>
                    </React.Fragment>
                  )
                })}
              </section>
            </div>
            <div className='calculating__period'>
              <NumberInput
                handleInputValue={(type, name) => numberInputsHandler(type, name)}
                label='تاریخ سررسید'
                name='period'
                value={period}
              />
              <span>هر‌ماه</span>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className='facilities'>
            <span>امکانات :</span>
            <div className='checkboxes'>
              {facilities?.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <label className='facilities__container'>
                      <span>{item.title}</span>
                      <span className='checkmark'></span>
                      <input type='checkbox' onChange={() => checkSelectedfacility(item.id)} />
                    </label>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div className='buttons-container'>
        <Button
          handler={() => handleClick('back')}
          height='35px'
          label='بازگشت'
          minWidth='50px'
          type='bordered'
          width='128px'
        />
        <Button
          handler={() => handleClick('next')}
          height='35px'
          label='بعدی'
          minWidth='50px'
          type='primary'
          width='128px'
        />
      </div>
    </div>
  )
}
