import React from 'react'

import './successPage.scss'

import BUILDING from '../../../assets/img/bg.jpg'

export default function SuccessPage() {
  return (
    <div className='success-page'>
      <div className='success-page__header'>
          {/*TODO put the texts into the language file*/}
        <h3> پرداخت موفق</h3>
        <div>
          <img src={BUILDING} />
        </div>
      </div>
      <div className='success-page__title'>
          {/*TODO put the texts into the language file*/}
        <span>ساختمان آزادی</span>
        <span>مبلغ شارژ تیر ماه</span>
      </div>
        {/*TODO put the texts into the language file*/}
      <h3>131300 تومان</h3>
      <hr />
      <div className='success-page__detail'>
        {mockData.map((item) => (
          <div className='success-page__box' key={item.id}>
            <span>{item.name}</span>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
//TODO put the texts into the language file
const mockData = [
  { id: 0, name: 'حاج امیر زندی', value: '43333333333' },
  { id: 1, name: 'پیمان شهبازی ', value: '415236476' },
  { id: 2, name: 'لیونل مسی', value: '09121111111' },
  { ide: 3, name: 'مجتبی جباری ', value: '09121111111' },
  { ide: 4, name: 'شیخ دیاباته ', value: '09121111111' },
]
