import React, { memo } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from './index'
import './styles.scss'

const ModalHeader = ({ children, title, ...otherProps }) => {
  return (
    <Modal {...otherProps}>
      <div className='ModalHeader'>
        <h2 className='ModalHeader_title'>{title}</h2>
        <span className='ModalHeader_close' onClick={() => otherProps.toggle(false)}>
          <FontAwesomeIcon color='#D9D9D9' icon={faTimes} size='2x' />
        </span>
      </div>
      <div className='ModalHeader_body'>{children}</div>
    </Modal>
  )
}

export default memo(ModalHeader)
