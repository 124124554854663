/* eslint-disable no-case-declarations */
import BLOG_ACTION_TYPES from '../constants/BlogConstants'
import initialState from '../initialState'

export default function BlogReducer(state = initialState.blogPosts, action) {
  switch (action.type) {
    case BLOG_ACTION_TYPES.BLOG_SUCCESS:
      const { data } = action.payload
      return [...data]

    default:
      return state
  }
}
