import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// import { faBuilding, faHandshake, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import './toast.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as sharedActions from '../../js/redux/actions/SharedActions'

export default function Toast(props) {
  // Redux State
  const notif = useSelector((state) => state.shared.notif)

  const { type, message, isEnabled } = notif

  // Redux actions
  const dispatch = useDispatch()

  // did update
  useEffect(() => {
    if (isEnabled) {
      setTimeout(() => {
        dispatch(sharedActions.killNotif())
      }, 3 * 1000)
    }
  }, [isEnabled])

  let icon
  const shouldEnabled = isEnabled ? 'toast__enabled' : 'toast__disabled'

  if (type === 'warn') icon = faExclamationCircle
  if (type === 'danger') icon = faTimesCircle
  if (type === 'success') icon = faCheckCircle

  return (
    <article className={`toast ${shouldEnabled} ${shouldEnabled}--${type} `}>
      <FontAwesomeIcon icon={icon} />

      <span>{message}</span>
    </article>
  )
}
