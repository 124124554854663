/* eslint-disable no-unused-vars */
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'
import { useRef, useEffect, useState } from 'react'

import './styles.scss'

const modalRoot = document.getElementById('modal-root')

function Modal({
  children,
  name = null,
  toggle = (x) => x,
  onTransitionEndOpen = (x) => x,
  onTransitionEndClose = (x) => x,
  vertical = true,
  direction = 1,
  hasRadius = true,
}) {
  // const location = useLocation()

  const locationState = window.history.state ? window.history.state.modal || [] : []

  const isOpen = locationState.includes(name)

  const [removeContent, setRemoveContent] = useState(true)

  const modalRef = useRef(document.createElement('div'))
  const backdropRef = useRef(document.createElement('div'))

  useEffect(() => {
    backdropRef.current.className = 'backdrop'
    modalRef.current.classList.add('modal')

    if (hasRadius) modalRef.current.classList.add('modal--hasRadius')
    if (vertical) {
      backdropRef.current.classList.add('backdrop--mode-vertical')
    } else {
      backdropRef.current.classList.add('backdrop--mode-horizontal')
    }
    if (direction === 1) {
      backdropRef.current.classList.add('backdrop--mode-default')
    } else {
      backdropRef.current.classList.add('backdrop--mode-reverse')
    }

    const node = backdropRef.current
    node.appendChild(modalRef.current)
    modalRoot.appendChild(node)
    return () => modalRoot.removeChild(node)
  }, [])

  function closeModal(e) {
    if (modalRef.current.contains(e.target)) return
    toggle(false, e)
  }

  useEffect(() => {
    if (isOpen) {
      setRemoveContent(false)
      backdropRef.current.addEventListener('click', closeModal)
    }
    return () => {
      backdropRef.current.removeEventListener('click', closeModal)
    }
  }, [isOpen])

  function transitionend(event) {
    if (event.target !== modalRef.current) return

    if (!isOpen) {
      deactivate()
      setRemoveContent(true)
      onTransitionEndClose(event)
    } else {
      onTransitionEndOpen(event)
    }
  }

  function deactivate() {
    backdropRef.current.classList.remove('backdrop--active')
  }

  useEffect(() => {
    modalRef.current.addEventListener('transitionend', transitionend)
    if (isOpen) {
      backdropRef.current.classList.add('backdrop--active', 'backdrop--appearing')
    } else {
      backdropRef.current.classList.remove('backdrop--appearing')
    }
    return () => modalRef.current.removeEventListener('transitionend', transitionend)
  }, [isOpen])

  return ReactDOM.createPortal(children, modalRef.current)
}

export default Modal
