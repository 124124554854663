import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

function TitleComponent() {
  const { t } = useTranslation()
  useEffect(() => {
    if (location) changeLanguage('en')
  }, [])

  // useEffect(() => {
  //   const withoutLogin = ['/home', '/login', '/signup', '/otp-login']
  //   const { history } = props
  //   if (!isAuth && !withoutLogin.includes(history.location.pathname)) history.push('/login')
  // }, [history.location.pathname])

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  const location = window.location.pathname.includes('en')
  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta content={t('hero-header')} name='description' />
    </Helmet>
  )
}

export default TitleComponent
