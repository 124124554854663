import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Service from '../../js/Api/Service'
import { setFacilitiesRequest, clearRequests } from '../../js/redux/actions/FacilitiesActions'

import ManagerRequestCard from '../facilitiesCard'

import './styles.scss'

const FacilitiesList = () => {
  const dispatch = useDispatch()

  const shared = useSelector((state) => state.shared)
  const facilities = useSelector((state) => state.facilities.requested)

  async function fetchRequestList() {
    const response = await Service.GET_MANAGER_FACILITIES_REQUEST({ id: shared.residence_id })
    if (response.status === 200) dispatch(setFacilitiesRequest(response.data))
  }

  useEffect(() => {
    dispatch(clearRequests())
    fetchRequestList()
  }, [])

  return (
    <div className='managerFacilitiesList'>
      <div className='managerFacilitiesList__filter'>
        <FontAwesomeIcon color='#999999' icon={faFilter} />
        <div className='managerFacilitiesList__select'>همه درخواست‌ها</div>
      </div>
      {facilities.map((info, index) => (
        <ManagerRequestCard key={index} {...info} fetchRequestList={fetchRequestList} />
      ))}
    </div>
  )
}

export default FacilitiesList
