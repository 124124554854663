/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PROFILE from '../../assets/img/bg.jpg'

import Content from './Content'

import './styles.scss'

function SelectBox(props) {
  const { items, width, type = 'input', handleSelectedItem, defaultTile = 'انتخاب', direction = 'down' } = props
  const [showItems, setShowItems] = useState(false)
  const [selectedItem, setSelectedItem] = useState(items && items[0])

  const selectItem = (item) => {
    setSelectedItem(item)
    setShowItems(false)
    handleSelectedItem(item.id)
  }

  SelectBox.handleClickOutside = () => {
    setShowItems(false)
  }

  // Redux State
  const userInformation = useSelector((state) => state.auth.userInformation)

  return (
    <Content onClose={() => setShowItems(false)}>
      <div className='select-box' style={{ width: width || '142px' }}>
        <div
          className={`select-box--arrow${type !== 'input' ? ' select-box--header' : ''}`}
          onClick={() => setShowItems(!showItems)}
        >
          {type === 'input' ? (
            <>
              <span>{selectedItem?.title || defaultTile}</span>
            </>
          ) : (
            <div className='select-box__profile'>
              <img alt='' src={PROFILE} />
              <div className='select-box__profile--title'>
{/*                  //TODO put the texts into the language file*/}

                <span>خوش آمدید </span>
                <span>{`${userInformation?.first_name} ${userInformation?.last_name}`}</span>
              </div>
            </div>
          )}
          {showItems ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
        </div>
        <div className={`select-box__body select-box--${direction}`} style={{ display: showItems ? 'flex' : 'none' }}>
          {items?.map((item) => (
            <div className={selectedItem === item ? 'selected' : ''} key={item.id} onClick={() => selectItem(item)}>
              {item.title}
              <i aria-hidden='true' className='fa fa-angle-down'></i>
            </div>
          ))}
        </div>
      </div>
    </Content>
  )
}

export default SelectBox
