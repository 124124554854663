import React from 'react'

import './registrationCode.scss'

import Header from '../../../components/header/Header'
import Button from '../../../components/button/Button'

export default function RegistrationCode(props) {
  return (
    <div className='registration-code'>
      <Header />
      <div className='registration-code__main'>
        <span> شما عضو هیچ ساختمان یا کسب و کاری نیستید </span>
        <Button
          handler={() => props.history.push('/add-building')}
          height='35px'
          label='افزودن'
          minWidth='50px'
          type='primary'
          width='155px'
        />
      </div>
    </div>
  )
}
