import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ResidentHeader from '../../../components/residentHeader'

import Button from '../../../components/button/Button'

import { persianDate } from '../../utils/moment'

import './styles.scss'

export default function ResidentAnouncemenets({ data }) {
  return (
    <div className='anouncemenets'>
      <ResidentHeader />
      <div className='anouncemenets__header'>
          {/*//TODO put the texts into the language file*/}
        <span>اعلانات ساختمان</span>
      </div>
      <div className='anouncemenets__resident'>
        {console.log(data)}
        {data?.map((item) => (
          <div key={item.id}>
            <FontAwesomeIcon icon={faBullhorn} />
            <span>{item.title}</span>
            <div className='anouncemenets__calendar'>
              <FontAwesomeIcon icon={faCalendar} />
              <span> {persianDate(item.publish_date)}</span>
            </div>

            <p>{item.description}</p>
              {/*TODO put the texts into the language file*/}
            <Button
              handler={() => console.log('object')}
              height='35px'
              label='اطلاعیه را خوانده ام'
              minWidth='135px'
              type='bordered'
              width='100px'
            />
          </div>
        ))}
      </div>
    </div>
  )
}
