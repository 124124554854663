/* eslint-disable no-case-declarations */
import AUTH_ACTION_TYPES from '../constants/AuthConstants'
import initialState from '../initialState'

export default function AuthReducer(state = initialState.auth, action) {
  const { token, data } = action.payload || ''

  switch (action.type) {
    case AUTH_ACTION_TYPES.HANDLE_REGISTER:
      return {
        ...state,
        id: data.id,
        userToken: token,
        phoneNumber: data.mobile_number,
        isAuth: true,
      }

    case AUTH_ACTION_TYPES.HANDLE_LOGIN:
      return {
        ...state,
        id: data.id,
        userToken: token,
        phoneNumber: data.mobile_number,
        isAuth: true,
      }

    case AUTH_ACTION_TYPES.HANDLE_LOGOUT:
      return {
        ...state,
        id: '',
        userToken: '',
        phoneNumber: '',
        isAuth: false,
      }

    case AUTH_ACTION_TYPES.HANDLE_USER_INFORMATION:
      console.log(action.payload)
      return {
        ...state,
        userInformation: { ...state.userInformation, ...action.payload },
      }

    case AUTH_ACTION_TYPES.HANDLE_AUTH_RESET:
      return {
        ...state,
        id: '',
        userToken: '',
        phoneNumber: '',
        isAuth: false,
      }

    default:
      return state
  }
}
