import { useState, useCallback } from 'react'

export const useModal = (name) => {
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const toggleModal = (status) => {
    const state = window.history.state ? window.history.state.modal || [] : []
    if (status) {
      state.push(name)
      window.history.pushState({ modal: state }, 'OPEN')
      forceUpdate()
    } else {
      if (!isModalOpen(name)) return
      window.history.back()
    }
  }
  return toggleModal
}

export const isModalOpen = (name) => {
  const state = window.history.state ? window.history.state.modal || [] : []
  return name ? state.includes(name) : state
}
