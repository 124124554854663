import React from 'react'
import { faTimesCircle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-jalaali'

import Modal from '../Modal/ModalPage'

import { useModal } from '../../js/hooks/useModal'

import { persianPrice } from '../../js/utils'
import { persianTime, persianDate } from '../../js/utils/moment'

import './styles.scss'

const icons = {
  waiting_for_manager: { icon: faSpinner, color: '#FED330', size: 'lg' },
  waiting_for_payment: { icon: faSpinner, color: '#999999', size: 'lg' },
  disapproved: { icon: faTimesCircle, color: '#FC5C65', size: 'lg' },
  approved: { icon: faCheckCircle, color: '#26DE81', size: 'lg' },
}

const ResidentRequestCard = ({
  facility,
  status,
  request_time_from,
  request_time_to,
  description,
  price,
  reason_of_disapproval,
}) => {
  const residentDetailModalName = 'resident-details-modal'
  const toggleModal = useModal(residentDetailModalName)

  const fromDate = persianDate(request_time_from)
  const toDate = persianDate(request_time_to)

  return (
    <div className={`residentFacilitiesCard ${status}`}>
      {fromDate === toDate ? (
        <div className='residentFacilitiesCard__facility'>
          <span>{facility.title}</span>
          <div className='residentFacilitiesCard__from'>
            <span>{persianDate(request_time_from)}</span>
          </div>
{/*            //TODO put the texts into the language file*/}

          <div className='residentFacilitiesCard__from'>
            <span>از ساعت</span>
            <span>{persianTime(request_time_from)}</span>
            <span>تا</span>
            <span>{persianTime(request_time_to)}</span>
          </div>
        </div>
      ) : (
        <div className='residentFacilitiesCard__facility'>
          <span>{facility.title}</span>
          <div className='residentFacilitiesCard__from'>
            <span>{persianDate(request_time_from)}</span>
            <span>- ساعت {persianTime(request_time_from)}</span>
          </div>
          <div className='residentFacilitiesCard__from'>
            <span>{persianDate(request_time_to)}</span>
            <span>- ساعت {persianTime(request_time_to)}</span>
          </div>
        </div>
      )}
      <div className='residentFacilitiesCard__status'>
        <span className={`residentFacilitiesCard__facility--${status}`}>
          {status === 'waiting_for_manager' ? (
            <>
              {status !== 'waiting_for_payment' && (
                <div>
                  <FontAwesomeIcon {...icons[status]} />
                </div>
              )}
{/*                //TODO put the texts into the language file*/}

              <span>در انتظار تایید مدیر</span>
            </>
          ) : status === 'waiting_for_payment' ? (
            <div>
              <div className='residentFacilitiesCard__payment'>
                <span>{persianPrice(price)}</span>
                <span>تومان</span>
              </div>
              <div className='residentFacilitiesCard__paymentBtn'>پرداخت</div>
            </div>
          ) : status === 'approved' ? (
            <>
              {status !== 'waiting_for_payment' && (
                <div>
                  <FontAwesomeIcon {...icons[status]} />
                </div>
              )}
              <div>تایید شده</div>
            </>
          ) : status === 'disapproved' ? (
            <>
              {status !== 'waiting_for_payment' && (
                <div>
                  <FontAwesomeIcon {...icons[status]} />
                </div>
              )}
              <span> عدم تایید</span>
              <div className='residentFacilitiesCard__rejectedBtn' onClick={() => toggleModal(true)}>
                دلیل عدم تایید
              </div>
            </>
          ) : (
            ''
          )}
          <div className='residentFacilitiesCard__details'>{description}</div>
        </span>
      </div>
      <Modal name={residentDetailModalName} title='دلیل عدم تایید' toggle={toggleModal}>
        <div>{reason_of_disapproval}</div>
        <div className='residentFacilitiesCard__button' onClick={() => toggleModal(false)}>
          بستن
        </div>
      </Modal>
    </div>
  )
}

export default ResidentRequestCard
