import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import './header.scss'

import Cookies from 'js-cookie'
import LOGO from '../../assets/img/logo.png'

import Service from '../../js/Api/Service'

import SelectBox from '../selectBox'

import * as SharedActions from '../../js/redux/actions/SharedActions'
import * as AuthActions from '../../js/redux/actions/AuthActions'

export default function Header(props) {
    //TODO put the texts into the language file
    const items = [
    { title: 'داشبورد', id: 1, path: '/dashboard' },
    { title: 'ویرایش پروفایل', id: 2, path: '/edit-profile' },
    { title: 'انتخاب ساختمان', id: 3, path: '/switch-building' },

    { title: 'خروج', id: 4, path: '' },
  ]

  // Redux State
  const residence_id = useSelector((state) => state.shared.residence_id)

  // Redux actions
  const dispatch = useDispatch()
  const history = useHistory()

  const exitProfile = async () => {
    const params = {}
    const response = await Service.POST_Logout(params)
    if (response?.status === 200) {
      dispatch(AuthActions.handleLogoutData())
      dispatch(AuthActions.handleAuthReset())

      Cookies.remove('token')
      // history.push('/login')
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  const handleSelectBoxItem = (id) => {
    if (id === 4) exitProfile()
    else if (id === 1 && !residence_id) {
      history.push('/switch-building')
      dispatch(
        SharedActions.setNotif({
          type: 'warn',
          message: `هنوز ساختمانی انتخاب یا اضافه نکرده اید.`,
        })
      )
    } else {
      history.push(items[id - 1].path)
    }
  }

  return (
    <div className='header'>
      <img alt='' src={LOGO} />

      <SelectBox handleSelectedItem={(item) => handleSelectBoxItem(item)} items={items} type='profile' />
    </div>
  )
}
