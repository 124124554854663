const SHARED_ACTION_TYPES = {
  // Notification
  SET_NOTIF: 'SET_NOTIF',
  KILL_NOTIF: 'KILL_NOTIF',

  SET_USERTYPE: 'SET_USERTYPE',
  SET_UNITDATA: 'SET_UNITDATA',
}

export default SHARED_ACTION_TYPES
