import React, { useState } from 'react'

import { faCloudUploadAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker from 'react-modern-calendar-datepicker'

import Modal from '../Modal/ModalPage'
import Input from '../input/Input'
import { useModal } from '../../js/hooks/useModal'

import './styles.scss'

const TYPES = {
  LOCAL: 'محلی',
  GLOBAL: 'عمومی',
}
const ads = [
  {
    type: 'LOCAL',
    body: 'این تبلیغات می توانند در تابلوی اعلانات و صفحات اعضای ساختمان نمایش داده شوند.',
  },
  {
    type: 'GLOBAL',
    body: 'این تبلیغات می توانند در تابلوی اعلانات و صفحات اعضای ساختمان نمایش داده شوند.',
  },
]

const SubmitAd = () => {
  const modalName = 'submit-ads'
  const toggleModal = useModal(modalName)

  const [type, setType] = useState(TYPES.LOCAL)
  const [agreed, setAgreed] = useState(false)
  const [date, setDate] = useState(null)

  const onChangeImage = () => {}
  const onChangeURL = () => {}
  const onSubmit = () => {
    toggleModal(false)
  }
  const onChangeTitle = () => {}
  const onChangeDuration = () => {}
  const onClickBox = (type) => {
    setType(type)
    toggleModal(true)
  }

  return (
    <div className='submitAd'>
      <div className='submitAd__title'>برای سفارش تبلیغات گستره نمایش را انتخاب و آگهی خود را سفارش دهید</div>
      <div className='submitAd__row'>
        {ads.map(({ type, body }) => (
          <div className='submitAd__box' key={type} onClick={() => onClickBox(type)}>
            <div className='submitAd__boxTitle'>{`تبلیغات ${TYPES[type]}`}</div>
            <div className='submitAd__boxBtn'>سفارش آگهی</div>
            <div className='submitAd__boxBody'>{body}</div>
          </div>
        ))}
      </div>
      <Modal name={modalName} title={`سفارش تبلیغات ${TYPES[type]}`} toggle={toggleModal}>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>عنوان آگهی:</span>
          </span>
          <Input handler={onChangeTitle} name='title' placeholder='نام کمپین' />
        </div>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>شروع از تاریخ:</span>
          </span>
          <div className='submitAd__date'>
            <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
            <DatePicker shouldHighlightWeekends inputPlaceholder='تاریخ' locale='fa' value={date} onChange={setDate} />
          </div>
        </div>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>به مدت:</span>
          </span>
          <Input handler={onChangeDuration} name='duration' placeholder='۲ ماه' />
        </div>
        <div className='submitAd__divider' />
        <div className='submitAd__image'>
          <div className='submitAd__imageTitle'>تصویر آگهی باید 200 در 600 پیکسل با فرمت JPG باشد</div>
        </div>
        <div className='submitAd__formRow browse'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>آپلود تصویر:</span>
          </span>
          <div>
            <label>
              <FontAwesomeIcon color='#ffa70b' icon={faCloudUploadAlt} />
              <span>آپلود فایل</span>
            </label>
            <input name='duration' placeholder='URL' type='file' onChange={onChangeImage} />
          </div>
        </div>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>لینک آگهی:</span>
          </span>
          <Input handler={onChangeURL} name='duration' placeholder='URL' />
        </div>
        <div className='submitAd__legal'>
          <div>قوانین استفاده از امکانات</div>
          <div>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون
            بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع
            با هدف بهبود ابزارهای کاربردی می باشد.
          </div>
        </div>
        <label className='submitAd__sign'>
          <input checked={agreed} type='checkbox' onChange={() => setAgreed(!agreed)} />
          <span>قوانین را می‌پذیرم</span>
        </label>
        <div className='submitAd__button' onClick={onSubmit}>
          ارسال درخواست
        </div>
      </Modal>
    </div>
  )
}

export default SubmitAd
