import { createStore, compose, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

// const migrations = {
//   0: (state) => ({ ...state, device: undefined }),
//   1: (state) => ({ device: state.device }),
//   2: (state) => ({ device: state.device }),
// }

const persistConfig = {
  key: 'root',
  storage,
  version: 2,
  // migrate: createMigrate(migrations, { debug: true }),
  whitelist: ['auth', 'shared'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [thunk]

export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(compose(applyMiddleware(...middlewares)))
  )

  const persistor = persistStore(store)

  return { store, persistor }
}
