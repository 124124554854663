import { combineReducers } from 'redux'

// V1 Reducers
import BlogReducer from './reducers/BlogReducer'
import AuthReducer from './reducers/AuthReducers'
import SharedReducer from './reducers/SharedReducer'
import FacilitiesReducer from './reducers/FacilitiesReducer'

const rootReducer = combineReducers({
  blogPosts: BlogReducer,
  auth: AuthReducer,
  shared: SharedReducer,
  facilities: FacilitiesReducer,
})

export default rootReducer
