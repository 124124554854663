/* eslint-disable no-case-declarations */
import SHARED_ACTION_TYPES from '../constants/SharedConstants'
import initialState from '../initialState'

export default function SharedReducer(state = initialState.shared, action) {
  switch (action.type) {
    // Notif
    case SHARED_ACTION_TYPES.SET_NOTIF:
      const { type, message } = action.payload
      return {
        ...state,
        notif: { ...state.notif, isEnabled: true, type, message },
      }

    case SHARED_ACTION_TYPES.KILL_NOTIF:
      return { ...state, notif: { isEnabled: false, type: state.notif.type, message: state.notif.message } }

    case SHARED_ACTION_TYPES.SET_USERTYPE:
      const { userType, residence_id } = action.payload
      return { ...state, userType, residence_id }

    case SHARED_ACTION_TYPES.SET_UNITDATA:
      const { unitData } = action.payload
      console.log(unitData)
      return { ...state, unitData }

    default:
      return state
  }
}
