/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'

export default function NumberInput(props) {
  const { value, label, width, minWidth, height, handleInputValue, name } = props

  return (
    <div className='number-specific'>
      <label htmlFor='blocks'>{label} : </label>
      <div className='input-nmuber'>
        <input
          disabled
          id='quantity'
          max='5'
          name='blocks'
          style={{ width, minWidth, height }}
          type='number'
          value={value}
          onChange={() => console.log('object')}
        />
        <div>
          <small onClick={() => handleInputValue('inc', name)}>+</small>
          <hr /> <small onClick={() => handleInputValue('dec', name)}>-</small>
        </div>
      </div>
    </div>
  )
}
